<template>
    <footer :class="['flex flex-col justify-end', textColor]">
        <div
            v-if="globals.footer"
            :class="[
                globals.footer.identity || globals.footer.show_contact ? classes.sectionPadding[globals.styles.spacing.value] : '',
                classes.bgColor[globals.footer.background_color?.value || 'white'],
            ]"
        >
            <div class="container grid grid-cols-1 lg:grid-cols-3">
                <div v-if="globals.footer.identity">
                    <figure v-if="globals.footer.logo" :class="classes.contentMarginBottom[globals.styles.spacing.value]">
                        <img
                            v-if="globals.footer.logo && logo"
                            :src="logo"
                            alt="Logo"
                            class="w-64"
                        >
                    </figure>

                    <p v-if="globals.footer.slogan">
                        {{ globals.footer.slogan }}
                    </p>
                </div>

                <div />

                <div v-if="globals.footer.show_contact" :class="['flex flex-col', classes.contentGap[globals.styles.spacing.value]]">
                    <a v-if="globals.footer.show_phone" :href="formattedOfficePhoneNumberUrl" class="flex items-start gap-3 hover:underline">
                        <AspectIcon :class="['size-6', classes.contentMarginBottomQuarter[globals.styles.spacing.value]]" name="phone" />
                        {{ globals.footer.phone_number }} <span v-if="globals.footer.extension">ext.</span> {{ globals.footer.extension }}
                    </a>

                    <a v-if="globals.footer.show_email" :href="formattedEmailAddressUrl" class="flex items-start gap-3 hover:underline">
                        <AspectIcon :class="['size-6', classes.contentMarginBottomQuarter[globals.styles.spacing.value]]" name="mail-send-envelope" />
                        {{ globals.footer.email }}
                    </a>

                    <div v-if="globals.footer.show_address" class="flex items-start gap-3">
                        <AspectIcon :class="['size-6', classes.contentMarginBottomQuarter[globals.styles.spacing.value]]" name="map-location" />
                        <p>
                            <template v-if="globals.footer.location_name">
                                <strong>{{ globals.footer.location_name }}</strong>
                                <br>
                            </template>
                            <span v-if="globals.footer.unit_apt">{{ globals.footer.unit_apt }}-</span>{{ globals.footer.street_number }} {{ globals.footer.street_name }}
                            <br>
                            <template v-if="globals.footer.country.value === 'ca'">
                                {{ globals.footer.city }} ({{ globals.footer.ca_province.label }}) {{ globals.footer.country.label }}
                            </template>
                            <template v-else-if="globals.footer.country.value === 'fr'">
                                {{ globals.footer.city }}, {{ globals.footer.fr_region.label }} {{ globals.footer.country.label }}
                            </template>
                            <template v-else-if="globals.footer.country.value === 'us'">
                                {{ globals.footer.city }}, {{ globals.footer.us_state.label }} {{ globals.footer.country.label }}
                            </template>
                            <br>
                            {{ globals.footer.ca_postal_code }}
                        </p>
                    </div>

                    <div v-if="globals.footer.social_media" :class="[ 'flex', classes.contentGap[globals.styles.spacing.value] ]">
                        <a
                            v-if="globals.footer.linkedin"
                            :href="globals.footer.linkedin_url"
                            class="transition hover:text-linkedin"
                            rel="external"
                            target="_blank"
                        >
                            <AspectIcon class="size-6" name="socials/linkedin" />
                        </a>

                        <a
                            v-if="globals.footer.facebook"
                            :href="globals.footer.facebook_url"
                            class="transition hover:text-facebook"
                            rel="external"
                            target="_blank"
                        >
                            <AspectIcon class="size-6" name="socials/facebook" />
                        </a>

                        <a
                            v-if="globals.footer.instagram"
                            :href="globals.footer.instagram_url"
                            class="transition hover:text-instagram"
                            rel="external"
                            target="_blank"
                        >
                            <AspectIcon class="size-6" name="socials/instagram" />
                        </a>

                        <a
                            v-if="globals.footer.twitter"
                            :href="globals.footer.twitter_url"
                            class="transition hover:text-x-twitter"
                            rel="external"
                            target="_blank"
                        >
                            <AspectIcon class="size-6" name="socials/x-twitter" />
                        </a>

                        <a
                            v-if="globals.footer.youtube"
                            :href="globals.footer.youtube_url"
                            class="transition hover:text-youtube"
                            rel="external"
                            target="_blank"
                        >
                            <AspectIcon class="size-6" name="socials/youtube" />
                        </a>
                    </div>
                </div>
            </div>
        </div>

        <!-- Sub Footer -->
        <div
            :class="[
                'text-center',
                textColor,
                subFooterBackgroundColor,
                classes.contentPaddingHalf[globals.styles.spacing.value],
            ]"
        >
            <div class="container">
                <span>
                    &copy; {{ pageProps.tenant.name }} <span v-if="globals.footer.founded_year && globals.footer.founded_year !== new Date().getFullYear()">{{ globals.footer.founded_year }}-</span>{{ new Date().getFullYear() }}
                </span>
            </div>
        </div>
    </footer>
</template>

<script lang="ts" setup>
    import { useSite } from '@/site/composables/use-site.ts';
    import { usePageProps } from '@aspect/shared/composables/use-page-props.ts';

    import AspectIcon from '@aspect/shared/components/aspect-icon.vue';

    const props = defineProps<{
        logoSrc?: string;
    }>();

    const { globals, classes } = useSite();

    const pageProps = usePageProps();

    const logo = computed<string | null>(() => {
        return props.logoSrc || pageProps.value.tenant.logo?.url || null;
    });

    const textColor = computed(() => {
        return {
            'black': 'text-gray-300',
            'dark-gray': 'text-gray-300',
            'dark-gray-alt': 'text-gray-300',
            'light-gray-alt': 'text-gray-800',
            'light-gray': 'text-gray-800',
            'white': 'text-gray-800',
        }[globals.value.footer.background_color?.value || 'white'];
    });

    const subFooterBackgroundColor = computed(() => {
        return {
            'black': 'bg-gray-950',
            'dark-gray': 'bg-black',
            'dark-gray-alt': 'bg-gray-900',
            'light-gray-alt': 'bg-gray-300',
            'light-gray': 'bg-gray-200',
            'white': 'bg-gray-100',
        }[globals.value.footer.background_color?.value || 'white'];
    });

    const formattedOfficePhoneNumberUrl = computed(() => {
        const formattedNumber = globals.value.footer.phone_number && globals.value.footer.phone_number.replace(/\D/g, '');
        const formattedExtension = globals.value.footer.extension && globals.value.footer.extension.replace(/\D/g, '');

        if (globals.value.footer.extension) {
            if (globals.value.footer.extension_behaviour === 'pause') {
                return `tel:${formattedNumber},${formattedExtension}`;
            }
            return `tel:${formattedNumber};${formattedExtension}`;
        }

        return `tel:${formattedNumber}`;
    });

    const formattedEmailAddressUrl = computed(() => {
        return `mailto:${globals.value.footer.email}`;
    });
</script>
