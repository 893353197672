<template>
    <SiteHead
        :tenant="tenant"
        :page="page"
    />

    <div
        v-if="globals.site_wide_banner.active && globals.site_wide_banner.position.value === 'top'"
        :class="globals.site_wide_banner.sticky && 'sticky inset-x-0 top-0'"
    >
        <SiteWideBanner />
    </div>

    <SiteNav />

    <SiteSections :page="page" />

    <div
        v-if="globals.site_wide_banner.active && globals.site_wide_banner.position.value === 'bottom'"
        :class="globals.site_wide_banner.sticky && 'sticky inset-x-0 bottom-0'"
    >
        <SiteWideBanner />
    </div>

    <SiteFooter />
</template>

<script lang="ts" setup>
    import { useSite } from '@/site/composables/use-site.ts';

    import SiteWideBanner from '@/site/components/site-wide-banner.vue';
    import SiteNav from '@/site/components/site-nav.vue';
    import SiteFooter from '@/site/components/site-footer.vue';
    import SiteHead from '@/site/components/site-head.vue';
    import SiteSections from '@/site/components/site-sections.vue';

    import type { TenantData } from '@aspect/shared/types/generated';

    defineOptions({
        inheritAttrs: false,
    });

    defineProps<{
        tenant: TenantData;
        page: {
            sections: any[];
            [x: string]: any;
        };
    }>();

    const { globals } = useSite();
</script>
